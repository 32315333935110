
import { inject, defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";

export default defineComponent({
  name: "ChecklistsList",
  components: {},
  props: {
    widgetClasses: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const items = [];
    const title = "Checklists list";
    const description = "List of all checklists available in the system";
    const dataLoaded = false;
    return {
      items,
      title,
      dataLoaded,
      description
    };
  },
  created: function() {
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  methods: {
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/documents/checklists/list")
        .then(data => {
          //console.log(data);
          this.$nextTick(() => {
            this.items = data.data.data;
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          //console.log(response);
        });
    }
  }
});
