<template>
  <div
    :class="[!dataLoaded && 'overlay overlay-block {{widgetClasses}}']"
    class="card"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1" :key="title">{{
          title
        }}</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{ description }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <div
        class="overlay-layer rounded bg-white bg-opacity-5"
        :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
        <!--begin::Table container-->
        <div class="table-responsive">
          <!--begin::Table-->
          <table
            class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
          >
            <!--begin::Table head-->
            <thead>
              <tr class="border-0">
                <th class="p-0 w-40px"></th>
                <th class="p-0"></th>
                <th class="p-0"></th>
                <th class="p-0 w-100px"></th>
                <th class="p-0 w-50px"></th>
              </tr>
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody>
              <template v-for="(item, index) in items" :key="index">
                <tr>
                  <td>
                    <!--begin::Symbol-->
                    <div class="symbol symbol-50px me-5">
                      <span
                        :class="`bg-primary`"
                        class="symbol-label text-light flex-column fw-bold fs-2 pt-1"
                      >
                          <span class="fs-8 text-light flex-row">REV</span>
                          {{item.rev}}
                      </span>
                    </div>
                  </td>
                  <td>
                    <router-link :to="`/document-db/checklists/view/${item.id}`" class="fw-bolder text-primary mb-1 fs-4">{{ item.mainTitle.replace('PS-SC', 'SG') }}</router-link>
                  </td>
                  <td class="text-start">
                    <span class="fw-bold d-block fs-5">{{
                        item.description
                      }}</span>
                  </td>
                  <td class="text-end">
                    <span class="text-muted fw-bold d-block">{{
                        item.modified
                      }}</span>
                  </td>
                  <td class="text-end">
                    <router-link :to="`/document-db/checklists/view/${item.id}`" class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                      <!--begin::Svg Icon | path: -->
                      <span class="svg-icon svg-icon-2">
                        <inline-svg
                            src="media/icons/duotone/Navigation/Arrow-right.svg"
                        />
                      </span>
                      <!--end::Svg Icon-->
                    </router-link>
                  </td>
                </tr>
              </template>
            </tbody>
            <!--end::Table body-->
          </table>
        </div>
        <!--end::Table-->
      </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import { inject, defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";

export default defineComponent({
  name: "ChecklistsList",
  components: {},
  props: {
    widgetClasses: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const items = [];
    const title = "Checklists list";
    const description = "List of all checklists available in the system";
    const dataLoaded = false;
    return {
      items,
      title,
      dataLoaded,
      description
    };
  },
  created: function() {
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  methods: {
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/documents/checklists/list")
        .then(data => {
          //console.log(data);
          this.$nextTick(() => {
            this.items = data.data.data;
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          //console.log(response);
        });
    }
  }
});
</script>
