
import { defineComponent, onMounted, onBeforeMount } from "vue";
import {setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";
import ChecklistsList from "@/components/widgets/tables/ChecklistsList.vue";

export default defineComponent({
  name: "ddb-checklists",
  components: {
    ChecklistsList
  },
  methods: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Checklists list");
      setCurrentPageToolbarType("ddb-checklists");
    });
  },
  data() {
    return {}
  }
});
